<template>
  <div
    class="
      text-center
      d-flex
      align-items-center
      justify-content-between
      full-screen
    "
  >
  {{ id }}
    <div class="text-center mx-auto">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BSpinner,
  },
  props: {
    customValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loggingIn: true,
      url:''
    };
  },
  methods: {
    validateAuthCode() {
      if(this.customValue === 'google-ads-callback') {
        this.url = "api/client/integrations/integrate/google-ads"
      } else if(this.customValue === 'google-dv360-callback') {
        this.url = "api/client/integrations/integrate/dv-360"
      } else {
        this.url = "api/client/integrations/integrate/google-my-business"
      }
      if (!this.$route.query.code || !this.$route.query.state) {
        return this.$router.push("/login");
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/${this.url}`,
          {
            code: this.$route.query.code,
            clientID: atob(this.$route.query.state),
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const clientSlug = response.data.slug; 
          this.$router.push(
            `/client/${clientSlug}?next=google-ads-customer-selection`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$router.push("/client/list?error=google-login-error");
        });
    },
  },
  mounted() {
    this.validateAuthCode();
  },
  computed: {
    id() {
      return this.$route.query.name;
    }
  }
};
</script>

<style scoped>
.full-screen {
  height: 100vh;
}
</style>